<template>
  <div>
    <div class="lg:flex lg:space-x-10">
      <div class="lg:px-20 space-y-7 w-full">
        <h1 class="lg:text-3xl text-2xl font-semibold mb-6">{{ discussion.title }}</h1>
        <div v-if="message">
          <alert :message="message" :status="status"/>
        </div>
        <form v-on:submit.prevent="updateDiscussion" class="grid grid-cols-1 gap-3">
          <div>
            <label for="title" class="font-semibold">Tiêu đề (*)</label>
            <input v-model="discussion.title" class="shadow-none with-border" id="title" type="text" autocomplete="off"
                   required>
          </div>
          <div>
            <label for="title" class="font-semibold">Chuyên mục (*)</label>
            <select v-model="discussion.forum_id">
              <optgroup :label="forum.title" v-for="forum in forums" :key="'forum' + forum.id">
                <template v-if="forum.children && forum.children.data">
                  <option :value="child.id" v-for="child in forum.children.data" :key="'forum' + child.id">
                    {{ child.title }}
                  </option>
                </template>
              </optgroup>
            </select>
          </div>
          <div>
            <label for="content" class="font-semibold">Nội dung (*)</label>
            <editor :full="true" id="content" v-model:content="discussion.content"/>
          </div>
          <div v-if="isAdmin">
            <label for="title" class="font-semibold">Khóa chủ đề? <i class="far fa-question-circle"
                                                                     :uk-tooltip="'Chủ đề bị khóa sẽ không thể bình luận'"></i></label>
            <label class="switch flex items-center mb-4">
              <input type="checkbox" v-model="isClosed">
              <span class="switch-button"></span>
            </label>
          </div>
          <div v-if="isAdmin">
            <label for="title" class="font-semibold">Ghim bình luận? <i class="far fa-question-circle"
                                                                        :uk-tooltip="'Comment mới nhất của chủ đề sẽ luôn hiện lên đầu'"></i></label>
            <label class="switch flex items-center mb-4">
              <input type="checkbox" v-model="isStickyComment">
              <span class="switch-button"></span>
            </label>
          </div>
          <div v-if="isAdmin">
            <label for="title" class="font-semibold">Ghim chủ đề? <i class="far fa-question-circle"
                                                                     :uk-tooltip="'Chủ đề được ghim sẽ hiện nổi bật trên trang chủ'"></i></label>
            <label class="switch flex items-center mb-4">
              <input type="checkbox" v-model="isSticky">
              <span class="switch-button"></span>
            </label>
          </div>
          <div v-if="isAdmin">
            <label for="title" class="font-semibold">Chủ đề riêng tư? <i class="far fa-question-circle"
                                                                         :uk-tooltip="'Chủ đề chỉ những người được cấp quyền mới xem được'"></i></label>
            <label class="switch flex items-center mb-4">
              <input type="checkbox" v-model="isPrivate">
              <span class="switch-button"></span>
            </label>
          </div>
          <div class="border-t flex justify-center border-gray-100 p-6">
            <button type="submit" class="button lg:w-1/3 hover:bg-blue-800">Cập nhật</button>
          </div>
          <div class="border-t flex justify-center border-gray-100 p-6">
            <button type="button" @click="deleteDiscussion" class="button lg:w-1/3 bg-red-800">Xóa chủ đề</button>
          </div>
        </form>
      </div>
    </div>

    <alert :message="message"/>
  </div>
</template>

<script>
import Editor from "../Editor";
import ApiService from "../../core/services/api.service";
import Alert from "../Alert";
import {getUser} from "../../core/services/jwt.service";
import {isAdmin, showAlert} from "../../core/services/utils.service";

export default {
  name: "DiscussionEdit",
  components: {Alert, Editor},
  data() {
    return {
      id: null,
      discussion: {
        title: "",
        content: "",
        is_closed: 0,
        sticky_comment: 0,
        is_sticky: 0,
        is_private: 0,
        forum_id: null
      },
      status: "",
      message: "",
      forums: [],
      isClosed: false,
      isStickyComment: false,
      isSticky: false,
      isPrivate: false
    }
  },
  methods: {
    loadDiscussion() {
      let query = `query($id: ID!) {
        discussion(id: $id) {
          id
          title
          slug
          forum_id
          is_closed
          is_sticky
          is_private
          sticky_comment
          content
          user_id
        }
      }`;

      ApiService.graphql(query, {id: this.id})
          .then(({data}) => {
            if (data.data && data.data.discussion) {
              if (!this.canEdit(data.data.discussion)) {
                this.$router.push({name: "ForumIndex"});
              }
              this.discussion = data.data.discussion;
              if (this.discussion.is_closed === 1) {
                this.isClosed = true;
              } else {
                this.isClosed = false;
              }
              if (this.discussion.sticky_comment === 1) {
                this.isStickyComment = true;
              } else {
                this.isStickyComment = false;
              }
              if (this.discussion.is_sticky === 1) {
                this.isSticky = true;
              } else {
                this.isSticky = false;
              }
              if (this.discussion.is_private === 1) {
                this.isPrivate = true;
              } else {
                this.isPrivate = false;
              }
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    updateDiscussion() {
      this.message = "";

      let query = `mutation($id: ID!, $title: String, $content: String, $forum_id: ID, $is_closed: Int, $sticky_comment: Int, $is_sticky: Int, $is_private: Int) {
        updateDiscussion(input: {
          id: $id,
          title: $title,
          forum_id: $forum_id,
          is_closed: $is_closed,
          sticky_comment: $sticky_comment,
          is_sticky: $is_sticky,
          is_private: $is_private,
          content: $content
        }) {
          id
          status
        }
      }`;

      ApiService.graphql(query, this.discussion)
          .then(({data}) => {
            if (data.data && data.data.updateDiscussion) {
              if (data.data.updateDiscussion.status === 2) {
                this.message = "Bài viết của bạn sẽ được BBT kiểm duyệt trước khi xuất hiện trên diễn đàn";
                showAlert();
              } else {
                this.$router.push({
                  name: "DiscussionDetail",
                  params: {id: this.discussion.id, slug: this.discussion.slug}
                });
              }
            } else {
              this.status = "error";
              this.message = data.errors[0].message;
              window.scrollTo(0, 0);
              showAlert();
            }
          })
          .catch((response) => {
            this.status = "error";
            this.message = response.message;
            window.scrollTo(0, 0);
            showAlert();
          });
    },
    deleteDiscussion() {
      if (confirm("Bạn có chắc muốn xóa chủ đề  này?")) {
        this.message = "";

        let query = `mutation($id: ID!) {
          deleteDiscussion(id: $id) {
            id
          }
        }`;

        ApiService.graphql(query, {id: this.discussion.id})
            .then(({data}) => {
              if (data.data && data.data.deleteDiscussion) {
                this.$toast.success("Xóa chủ đề thành công");
                this.$router.push({
                  name: "Home"
                });
              } else {
                this.$toast.error(data.errors[0].message);
              }
            })
            .catch((response) => {
              this.$toast.error(response.message);
            });
      }
    },
    loadForums() {
      let query = `query {
        forums(first: 100, where: {AND: [{column: "parent_id", value: null}]}, orderBy: [{column: "position", order: ASC}]) {
          data {
            id
            title
            children(first: 100) {
              data {
                id
                title
              }
            }
          }
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.forums && data.data.forums.data) {
              this.forums = data.data.forums.data;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    canEdit(discussion) {
      let user = getUser();

      if (user && user.profile_id === 1) {
        return true;
      }

      if (user && user.id && parseInt(user.id) === parseInt(discussion.user_id)) {
        return true;
      }

      return false;
    }
  },
  watch: {
    isClosed: {
      handler(newVal) {
        if (newVal) {
          this.discussion.is_closed = 1;
        } else {
          this.discussion.is_closed = 0;
        }
      }
    },
    isStickyComment: {
      handler(newVal) {
        if (newVal) {
          this.discussion.sticky_comment = 1;
        } else {
          this.discussion.sticky_comment = 0;
        }
      }
    },
    isSticky: {
      handler(newVal) {
        if (newVal) {
          this.discussion.is_sticky = 1;
        } else {
          this.discussion.is_sticky = 0;
        }
      }
    },
    isPrivate: {
      handler(newVal) {
        if (newVal) {
          this.discussion.is_private = 1;
        } else {
          this.discussion.is_private = 0;
        }
      }
    }
  },
  mounted() {
    this.id = this.$route.params.id;
    this.loadDiscussion();
    this.loadForums();
  },
  computed: {
    isAdmin() {
      return isAdmin();
    }
  }
}
</script>
